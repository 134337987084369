import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { SectionWrapper, SectionText, H2 } from 'styles/Typography.styled';

export const StyledSectionWrapper = styled(SectionWrapper)`
    display: flex;
    margin-bottom: 100px;
    padding: 160px 0 0;

    ${mediaQueries.md} {
        padding: 56px 16px;
    }
    ${mediaQueries.sm} {
        flex-direction: column;
        gap: 40px;
        margin-bottom: 0px;
    }
`;

export const LeftWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-right: 100px;

    ${mediaQueries.sm} {
        order: -1;
        padding-right: 0;
    }
`;

export const RightWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 24px;
`;

export const Title = styled(H2)`
    margin-top: 36px;
`;

export const Description = styled(SectionText)`
    margin-top: 24px;
`;

export const Image = styled.img`
    max-height: 264px;
    max-width: 100%;
    object-fit: contain;
    overflow: hidden;

    ${mediaQueries.sm} {
        display: none;
    }
`;

export const ImageMobile = styled.img`
    display: none;

    ${mediaQueries.sm} {
        display: block;
    }
    ${mediaQueries.xs} {
        max-width: 100%;
    }
`;

export const SectionTextWrapper = styled.div``;

export const SectionTextWithDotWrapper = styled.div`
    margin-left: 18px;
    position: relative;
`;

export const SectionTextWithDot = styled(SectionText)(
    ({ theme: { colors } }) => css`
        ::before {
            background-color: ${colors.textSection};
            border-radius: 3px;
            content: '';
            height: 3px;
            left: -8px;
            position: absolute;
            top: 12px;
            width: 3px;
        }
    `,
);
